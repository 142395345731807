<template>
  <div class="deals-list">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="6" md="3" sm="6">
              <b-form-input
                v-model="search"
                debounce="50"
                placeholder="Поиск..."
              />
            </b-col>
            <b-col cols="6" md="3" sm="6">
              <b-form-select
                v-model="isPaid"
                :options="paidOptions"
                text-field="display_name"
                value-field="value"
              />
            </b-col>
            <b-col class="d-flex align-items-center" cols="6" md="6" sm="6">
              <b-form-checkbox v-model="isDelayed" class="d-block" inline
                >Наличие текущей просрочки
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-overlay :show="isLoading" rounded="sm">
                <b-table
                  :fields="fields"
                  :items="deals"
                  empty-text="Нет данных"
                  hover
                  responsive
                  show-empty
                  small
                  @row-clicked="openDeal"
                >
                  <template
                    v-for="field in fields"
                    #[`cell(${field.key})`]="{ item }"
                  >
                    <router-link
                      :key="field.key"
                      :to="`/deals/${item.id}`"
                      class="w-100"
                      style="color: #6e6b7b"
                    >
                      {{ item[field.key] }}
                    </router-link>
                  </template>
                  <template #head(document_number)="data">
                    <span @click="toggleOrdering('document_number')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(initials)="data">
                    <span @click="toggleOrdering('initials')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(date_document_start)="data">
                    <span @click="toggleOrdering('date_document_start')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(date_document_end)="data">
                    <span @click="toggleOrdering('date_document_end')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(next_payment_day)="data">
                    <span @click="toggleOrdering('next_payment_day')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(days_delayed)="data">
                    <span @click="toggleOrdering('days_delayed')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(amount_to_pay_for_today)="data">
                    <span @click="toggleOrdering('amount_to_pay_for_today')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(current_sum_of_fines)="data">
                    <span @click="toggleOrdering('current_sum_of_fines')">
                      {{ data.label }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #head(is_paid_for_today)>
                    <span @click="toggleOrdering('is_paid_for_today')">
                      Оплачено за {{ month }} <b-icon-sort-alpha-down />
                    </span>
                  </template>
                  <template #cell(is_paid_for_today)="value">
                    <b-badge v-if="value.value" href="#" variant="success"
                      >Оплачено
                    </b-badge>
                    <b-badge v-else href="#" variant="danger"
                      >Не оплачено
                    </b-badge>
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DealsList',
  data() {
    return {
      month: '',
      ordering: '',
      search: '',
      isPaid: 1,
      isDelayed: false,
      isLoading: false,
      deals: [],
      paidOptions: [
        { value: 0, display_name: 'Оплачены' },
        { value: 1, display_name: 'Не оплачены' },
        { value: 2, display_name: 'Все' },
      ],
      fields: [
        {
          key: 'document_number',
          label: 'Договор',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'initials',
          label: 'Имя',
        },
        {
          key: 'value',
          label: 'Сумма, BYN',
        },
        {
          key: 'rate',
          label: 'Ставка',
          formatter: (value) => {
            return `${parseFloat(value) * 100}%`
          },
        },
        {
          key: 'date_document_start',
          label: 'Дата договора',
        },
        {
          key: 'date_document_end',
          label: 'Дата окончания',
        },
        {
          key: 'next_payment_day',
          label: 'Дата оплаты',
        },
        {
          key: 'is_paid_for_today',
          label: '',
        },
        {
          key: 'days_delayed',
          label: `Дней просрочки`,
        },
        {
          key: 'amount_to_pay_for_today',
          label: `Сумма к оплате`,
        },
        {
          key: 'current_sum_of_fines',
          label: `Сумма неустоек`,
        },
      ],
    }
  },
  watch: {
    search() {
      this.getDelayedDeals()
    },
    ordering() {
      this.deals = this.deals.sort(this.sortByField(this.ordering))
    },
    isPaid() {
      this.getDelayedDeals()
    },
    isDelayed() {
      this.getDelayedDeals()
    },
  },
  mounted() {
    const formatter = new Intl.DateTimeFormat('ru', { month: 'long' })
    this.month = formatter.format(new Date())
  },
  created() {
    this.getDelayedDeals()
  },
  methods: {
    async getDelayedDeals() {
      this.isLoading = true
      const res = await this.$http.get('admin/delays/')
      this.deals = res.data.results
        .filter(
          (item) =>
            item.initials.toLowerCase().includes(this.search.toLowerCase()) ||
            item.document_number.includes(this.search) ||
            item.phone.includes(this.search)
        )
        .filter((item) =>
          this.isPaid === 0
            ? item.is_paid_for_today
            : this.isPaid === 1
            ? !item.is_paid_for_today
            : item
        )
        .filter((item) => (this.isDelayed ? item.days_delayed > 0 : item))
      this.isLoading = false
    },
    openDeal(item) {
      this.$router.push({ name: 'deal-view', params: { id: item.id } })
    },
    toggleOrdering(name) {
      if (name.includes(this.ordering)) {
        this.ordering = `-${name}`
      } else {
        this.ordering = name
      }
    },
    sortByField(field) {
      if (
        field === 'date_document_start' ||
        field === 'date_document_end' ||
        field === 'next_payment_day'
      ) {
        return (a, b) => {
          if (a[field] === null && b[field] === null) {
            return 0
          } else if (a[field] === null) {
            return 1
          } else if (b[field] === null) {
            return -1
          } else {
            return (
              new Date(a[field].split('.').reverse().join('.')).getTime() -
              new Date(b[field].split('.').reverse().join('.')).getTime()
            )
          }
        }
      } else if (
        field === 'document_number' ||
        field === 'amount_to_pay_for_today' ||
        field === 'current_sum_of_fines' ||
        field === 'days_delayed'
      ) {
        return (a, b) => a[field] - b[field]
      }
      return (a, b) => (a[field] > b[field] ? 1 : -1)
    },
  },
}
</script>
